export default {
  //MEDIA_ROOT_URL: "https://schoolroomhelp.20thfloor.us/",
  MEDIA_ROOT_URL: "https://schoolroomhelp.20thfloor.com/",
 	// MEDIA_ROOT_URL: "https://schoolroomhelp.com/",
 	// MEDIA_ROOT_URL: "http://localhost:8000/",

 	//20thfloor paypal

 	//PayPal_key : "AfZ2PrH95E8LeVnVkfMSgTI15l19ND-tm66CUbT_zWokpJvGLKL9WdHvYVeH3rJo8Wb0eJnhsZEKbBzz",
 	

 	//SRH paypal
 	PayPal_key : "Af4R2mdSCajafDOEYKKdPk6jgWOUk0kgMXBGsAk1Gqce9cYx3wCJGqfly-X5Qu2KVxtm6jE9S7A_DA6a",
};
