const Utils={
  getYoutubeVideoID:function(url){
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  },
  YoutubeEmbedURL:function(url){
    var video_id=this.getYoutubeVideoID(url);
    return "https://www.youtube.com/embed/"+video_id+"?&modestbranding=1";
  },
  YoutubeRegex:function () {
    return /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
  },

  redirectWithToken: function (url, access_token, userRole, user_Id) {
        let target_url = url + '/authorize?access_token=' + access_token + '&role=' + userRole+'&user_id='+user_Id;
        console.log("Redirecting with Token", url,access_token,userRole,target_url)
        window.open(target_url,"_blank")
        
    }
};
export default Utils
