import React from "react";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  NavItem,
  NavLink,
  UncontrolledDropdown,UncontrolledTooltip,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Icon from "react-feather";
import classnames from "classnames";
import ReactCountryFlag from "react-country-flag";
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent";
import {useAuth0} from "../../../authServices/auth0/auth0Service";
import {history} from "../../../history";
import {Link} from "react-router-dom";
import {connect} from "react-redux"
import axios from "./../../../axios";
import {setNotifications} from "./../../../redux/actions/notifications";
import SiteUrl from '../../../appKeys'
const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path);
};

const UserDropdown = (props) => {
  const {logout, isAuthenticated} = useAuth0();
  return (
    <DropdownMenu right className="navbarSky">
      <DropdownItem
        tag="a"
        href="#"
        onClick={(e) => handleNavigation(e, "/account-settings")}
      >
        <Icon.Settings className="primary" size={14} className="mr-50"/>
        <span className="align-middle">Account Settings</span>
      </DropdownItem>

      {/*<DropdownItem
        tag="a"
        href="#"
        onClick={(e) => handleNavigation(e, "#")}
      >
        <Icon.MessageSquare size={14} className="mr-50"/>
        <span className="align-middle">Chat</span>
      </DropdownItem>*/}
      <DropdownItem
        tag="a"
        href="#"
        onClick={(e) => handleNavigation(e, "/notifications")}
      >
        <Icon.Bell size={14} className="primary" className="mr-50"/>
        <span className="align-middle">Notifications</span>
      </DropdownItem>

      <DropdownItem divider/>
      <DropdownItem
        tag="a"
        href="/authorize?log_out=true"
        onClick={(e) => {
          e.preventDefault();
          if (isAuthenticated) {
            return logout({
              returnTo:
                window.location.origin + process.env.REACT_APP_PUBLIC_PATH,
            });
          } else {
            const provider = props.loggedInWith;
            if (provider !== null) {
              if (provider === "jwt") {
                return props.logoutWithJWT();
              }
              if (provider === "firebase") {
                return props.logoutWithFirebase();
              }
            } else {
              history.push("/authorize?log_out=true");
            }
          }
        }}
      >
        <Icon.Power size={14} className="primary" className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    all_type_notifications : [],
    navbarSearch: false,
    langDropdown: false,
    shoppingCart: [
      {
        id: 1,
        name:
          "Apple - Apple Watch Series 1 42mm Space Gray Aluminum Case Black Sport Band - Space Gray Aluminum",
        desc:
          "Durable, lightweight aluminum cases in silver, space gray, gold, and rose gold. Sport Band in a variety of colors. All the features of the original Apple Watch, plus a new dual-core processor for faster performance. All models run watchOS 3. Requires an iPhone 5 or later.",
        price: "$299",
        img: require("../../../assets/img/pages/eCommerce/4.png"),
        width: 75,
      },
      {
        id: 2,
        name:
          "Apple - Macbook® (Latest Model) - 12' Display - Intel Core M5 - 8GB Memory - 512GB Flash Storage Space Gray",
        desc:
          "MacBook delivers a full-size experience in the lightest and most compact Mac notebook ever. With a full-size keyboard, force-sensing trackpad, 12-inch Retina display,1 sixth-generation Intel Core M processor, multifunctional USB-C port, and now up to 10 hours of battery life,2 MacBook features big thinking in an impossibly compact form.",
        price: "$1599.99",
        img: require("../../../assets/img/pages/eCommerce/dell-inspirion.jpg"),
        width: 100,
        imgClass: "mt-1 pl-50",
      },
      {
        id: 3,
        name: "Sony - PlayStation 4 Pro Console",
        desc:
          "PS4 Pro Dynamic 4K Gaming & 4K Entertainment* PS4 Pro gets you closer to your game. Heighten your experiences. Enrich your adventures. Let the super-charged PS4 Pro lead the way.** GREATNESS AWAITS",
        price: "$399.99",
        img: require("../../../assets/img/pages/eCommerce/7.png"),
        width: 88,
      },
      {
        id: 4,
        name:
          "Beats by Dr. Dre - Geek Squad Certified Refurbished Beats Studio Wireless On-Ear Headphones - Red",
        desc:
          "Rock out to your favorite songs with these Beats by Dr. Dre Beats Studio Wireless GS-MH8K2AM/A headphones that feature a Beats Acoustic Engine and DSP software for enhanced clarity. ANC (Adaptive Noise Cancellation) allows you to focus on your tunes.",
        price: "$379.99",
        img: require("../../../assets/img/pages/eCommerce/10.png"),
        width: 75,
      },
      {
        id: 5,
        name:
          "Sony - 75' Class (74.5' diag) - LED - 2160p - Smart - 3D - 4K Ultra HD TV with High Dynamic Range - Black",
        desc:
          "This Sony 4K HDR TV boasts 4K technology for vibrant hues. Its X940D series features a bold 75-inch screen and slim design. Wires remain hidden, and the unit is easily wall mounted. This television has a 4K Processor X1 and 4K X-Reality PRO for crisp video. This Sony 4K HDR TV is easy to control via voice commands.",
        price: "$4499.99",
        img: require("../../../assets/img/pages/eCommerce/sony-75class-tv.jpg"),
        width: 100,
        imgClass: "mt-1 pl-50",
      },
      {
        id: 6,
        name:
          "Nikon - D810 DSLR Camera with AF-S NIKKOR 24-120mm f/4G ED VR Zoom Lens - Black",
        desc:
          "Shoot arresting photos and 1080p high-definition videos with this Nikon D810 DSLR camera, which features a 36.3-megapixel CMOS sensor and a powerful EXPEED 4 processor for clear, detailed images. The AF-S NIKKOR 24-120mm lens offers shooting versatility. Memory card sold separately.",
        price: "$4099.99",
        img: require("../../../assets/img/pages/eCommerce/canon-camera.jpg"),
        width: 70,
        imgClass: "mt-1 pl-50",
      },
    ],
    suggestions: [],
    notifications: [],
  };


  componentDidMount() {
    // axios.get("/main-search/data").then(({ data }) => {
    //   this.setState({ suggestions: data.searchResult });
    // });
    //  Restrict Fetch Notification request

    this.fetchNotifications();

  }
 

  fetchNotifications = () => {
    console.log("Fetch Notifications")
    // const data={"take" : 5, "page" : 1}
    const data = {}
    axios
      .post("/get-notifications", data)
      .then((res) => {
        console.log("Fetch Notifications - Response", res);
        const notifications = res.data.records.data;
        this.setState({
          all_type_notifications : res.data.records.data
        });
        

        
        //  TODO Enable/Disable Notifications
        this.props.dispatch(setNotifications(notifications));
        
      })
      .catch((err) => {
        console.log("Fetch Notifications - Response - Error", err);
      });
  };
  readNotification = (id) => {
    
       const data={'id':id}
     axios
      .post("/read-notification", data)
      .then((res) => {
        console.log("Fetch Notifications - Response", res);
        console.log('res')

        this.fetchNotifications();

        console.log("Dispatch Start")
        //  TODO Enable/Disable Notifications
       
        console.log("Dispatch End")
      })
      .catch((err) => {
        console.log("Fetch Notifications - Response - Error", err);
      });
  
  };

   readAllNotification = () => {
    
     axios
      .get("/read-all-notification")
      .then((res) => {
        console.log("Fetch Notifications - Response", res);
        console.log('res')
        const pathname = window.location.pathname
        
        this.fetchNotifications();
        
        if(pathname == '/notifications'){
          history.push('/');
          history.replace('/notifications');
          //window.location.reload(false);
        }else{
          history.push("/notifications")
        }

        console.log("Dispatch Start")
        //  TODO Enable/Disable Notifications
       
        console.log("Dispatch End")
      })
      .catch((err) => {
        console.log("Fetch Notifications - Response - Error", err);
      });
  };

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  removeItem = (id) => {
    let cart = this.state.shoppingCart;

    let updatedCart = cart.filter((i) => i.id !== id);

    this.setState({
      shoppingCart: updatedCart,
    });
  };

  handleLangDropdown = () =>
    this.setState({langDropdown: !this.state.langDropdown});

  render() {
    const renderCartItems = this.state.shoppingCart.map((item) => {
      return (
        <div className="cart-item" key={item.id}>
          <Media
            className="p-0"
            onClick={() => history.push("/ecommerce/product-detail")}
          >
            <Media className="text-center pr-0 mr-1" left>
              <img
                className={`${
                  item.imgClass
                    ? item.imgClass + " cart-item-img"
                    : "cart-item-img"
                }`}
                src={item.img}
                width={item.width}
                alt="Cart Item"
              />
            </Media>
            <Media className="overflow-hidden pr-1 py-1 pl-0" body>
              <span className="item-title text-truncate text-bold-500 d-block mb-50">
                {item.name}
              </span>
              <span className="item-desc font-small-2 text-truncate d-block">
                {item.desc}
              </span>
              <div className="d-flex justify-content-between align-items-center mt-1">
                <span className="align-middle d-block">1 x {item.price}</span>
                <Icon.X
                  className="danger"
                  size={15}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.removeItem(item.id);
                  }}
                />
              </div>
            </Media>
          </Media>
        </div>
      );
    });
    const unreadNotifications = this.state.all_type_notifications.filter(d => d.read_at === "");
    const notificationsItems = this.state.all_type_notifications.map((notification) => {
      return (
      <Link to="/notifications" onClick={() => this.readNotification(notification.id)}>
        <div className="d-flex justify-content-between" key={notification.id} >
          <Media  className="d-flex align-items-start">
            <Media left>
              <Icon.Info className="font-medium-5 primary" size={21}/>
            </Media>
            <Media body>
              <Media
                heading
                className={`primary media-heading ${
                  notification.read_at !== ""
                    ? "font-weight-normal"
                    : "font-weight-bold"
                }`}
                tag="p"
                dangerouslySetInnerHTML={{__html: notification.message}}
              ></Media>
              {/* <p className="notification-text">
              Are your going to meet me tonight?
            </p> */}
            </Media>
            <small>
              <time className="media-meta" dateTime="2015-06-11T18:29:20+08:00">
                {notification.timestamp &&
                new Date(notification.timestamp).toLocaleDateString()}
              </time>
            </small>
          </Media>
        </div>
        </Link>
      );
    });
    let profilePhoto = SiteUrl.MEDIA_ROOT_URL + this.props.user.profile.photo;
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        
        <NavItem className="dropdown-language nav-item">
          <NavLink className="nav-link-search">
            <ReactCountryFlag className="country-flag" countryCode="us" svg/>
            <span className="ml-1">English</span>
          </NavLink>
        </NavItem>
        <NavItem className="nav-search nav-item">
          <NavLink className="nav-link-search">
            <a href="https://schoolroomhelp.com/faqs" target="_blank" ><span className="ml-1"><Icon.HelpCircle className="primary" id="faq"/><UncontrolledTooltip placement="top" target="faq">FAQ's</UncontrolledTooltip></span></a>
            
          </NavLink>
        </NavItem>
        <NavItem className="nav-search" onClick={this.handleNavbarSearch}>
          {/*<NavLink className="nav-link-search">
            <Icon.Search className="primary" size={21} data-tour="search" />
          </NavLink>*/}
          <div
            className={classnames("search-input", {
              open: this.state.navbarSearch,
              "d-none": this.state.navbarSearch === false,
            })}
          >
            <div className="search-input-icon">
              <Icon.Search size={17} className="primary"/>
            </div>
            <Autocomplete
              className="form-control"
              suggestions={this.state.suggestions}
              filterKey="title"
              filterHeaderKey="groupTitle"
              grouped={true}
              placeholder="Explore School Room Help..."
              autoFocus={true}
              clearInput={this.state.navbarSearch}
              externalClick={(e) => {
                this.setState({navbarSearch: false});
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 27 || e.keyCode === 13) {
                  this.setState({
                    navbarSearch: false,
                  });
                  this.props.handleAppOverlay("");
                }
              }}
              customRender={(
                item,
                i,
                filteredData,
                activeSuggestion,
                onSuggestionItemClick,
                onSuggestionItemHover
              ) => {
                const IconTag = Icon[item.icon ? item.icon : "X"];
                return (
                  <li
                    className={classnames("suggestion-item", {
                      active: filteredData.indexOf(item) === activeSuggestion,
                    })}
                    key={i}
                    onClick={(e) => onSuggestionItemClick(item.link, e)}
                    onMouseEnter={() =>
                      onSuggestionItemHover(filteredData.indexOf(item))
                    }
                  >
                    <div
                      className={classnames({
                        "d-flex justify-content-between align-items-center":
                          item.file || item.img,
                      })}
                    >
                      <div className="item-container d-flex">
                        {item.icon ? (
                          <IconTag size={17}/>
                        ) : item.file ? (
                          <img
                            src={item.file}
                            height="36"
                            width="28"
                            alt={item.title}
                          />
                        ) : item.img ? (
                          <img
                            className="rounded-circle mt-25"
                            src={item.img}
                            height="28"
                            width="28"
                            alt={item.title}
                          />
                        ) : null}
                        <div className="item-info ml-1">
                          <p className="align-middle mb-0">{item.title}</p>
                          {item.by || item.email ? (
                            <small className="text-muted">
                              {item.by
                                ? item.by
                                : item.email
                                  ? item.email
                                  : null}
                            </small>
                          ) : null}
                        </div>
                      </div>
                      {item.size || item.date ? (
                        <div className="meta-container">
                          <small className="text-muted">
                            {item.size
                              ? item.size
                              : item.date
                                ? item.date
                                : null}
                          </small>
                        </div>
                      ) : null}
                    </div>
                  </li>
                );
              }}
              onSuggestionsShown={(userInput) => {
                if (this.state.navbarSearch) {
                  this.props.handleAppOverlay(userInput);
                }
              }}
            />
            <div className="search-input-close">
              <Icon.X
                size={24}
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({
                    navbarSearch: false,
                  });
                  this.props.handleAppOverlay("");
                }}
              />
            </div>
          </div>
        </NavItem>
        
        <NavItem className="nav-search nav-item">
          <NavLink className="nav-link-search">
            <Link to='/wallet'><Icon.Briefcase className="primary" size={21}/></Link>
          </NavLink>
        </NavItem>
        <NavItem className="nav-search nav-item">
          <NavLink className="nav-link-search">
            <Link to='/credits-top-up'><Icon.CreditCard className="primary" size={21}/></Link>
          </NavLink>
        </NavItem>

        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell className="primary" size={21}/>
            <Badge pill color="primary" className="badge-up">
              {" "}
              {notificationsItems.length}{" "}
            </Badge>
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">{unreadNotifications.length} New</h3>
                <span className="notification-title">Notifications</span>
              </div>
            </li>

            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              {notificationsItems}
            </PerfectScrollbar>
            <li className="dropdown-menu-footer">
              <DropdownItem tag={Link} className="p-1 text-center">
                <span className="align-middle" onClick={() => this.readAllNotification()}>Read all notifications</span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600" style={{textTransform: 'capitalize'}}>
                {this.props.userName}
              </span>
              <span className="user-status">School Admin</span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.user.profile ? profilePhoto : this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  notifications: state.notification,
  appState: state.appState,
});

export default connect(mapStateToProps)(NavbarUser);
