import React from "react"
import * as Icon from "react-feather"
import config from "./../config";

const navigationConfig = [
  {
    id: "Home",
    title: "Home",
    type: "external-link",
    icon: <Icon.Home size={20} color="#0e0aeb"/>,
    navLink: config.home_url
  },
  {
    id: "feeds",
    title: "Feeds",
    type: "item",
    icon: <Icon.Rss size={20} color="#85cb1f"/>,
    badge: "warning",
    navLink: "/feeds"
  },
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Grid size={20} color="#b448b5"/>,
    badge: "warning",
    navLink: "/"
  },
  {
    id: "student-dashboard",
    title: "Student Dashboard",
    type: "item",
    icon: <Icon.User size={20} color="#1f0f3d"/>,
    badge: "warning",
    navLink: "/student-dashboard"
  },
  {
    type: "groupHeader",
    groupTitle: "MODULES"
  },
  {
    id: "calendar",
    title: "Calendar",
    type: "item",
    icon: <Icon.Calendar size={20} color="#32ad2b"/>,
    permissions: ["admin", "editor"],
    navLink: "/calendar"
  },
  {
   id: "appointment",
   title: "My Appointments",
   type: "item",
   icon: <Icon.Airplay size={20} color="#4c5456"/>,
   permissions: ["admin", "editor"],
   navLink: "/my-appointments"
  },
  {
    id: "Manage_school",
    title: "Manage School",
    type: "item",
    icon: <Icon.Globe size={20} color="#71ed63"/>,
    permissions: ["admin", "editor"],
    navLink: "/school-profile"
  },
  {
    id: "Manage_students",
    title: "Manage Students",
    type: "item",
    icon: <Icon.Users size={20} color="#32ad2b"/>,
    permissions: ["admin", "editor"],
    navLink: "/manage-students"
  },
  {
    id: "Manage_classes",
    title: "Manage Classes",
    type: "collapse",
    icon: <Icon.Clock size={20} color="#9c8c1c"/>,
    children: [
      {
        id: "View_classes",
        title: "View Classes",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin", "editor"],
        navLink: "/manage-classes"
      },
      {
        id: "Approval_classes",
        title: "Approval Classes",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin", "editor"],
        navLink: "/manage-classes/approval-classes"
      },
      {
        id: "Completed_classes",
        title: "Completed Classes",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin", "editor"],
        navLink: "/manage-classes/completed-classes"
      },
      {
        id: "Ongoing_classes",
        title: "Current Classes",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin", "editor"],
        navLink: "/manage-classes/current-classes"
      }
    ]
  },
  {
    id: "Manage_topics",
    title: "My Topics",
    type: "collapse",
    icon: <Icon.Server size={20} color="#9c8cfc"/>,
    children: [
      {
        id: "my_topic_question",
        title: "My Topic Questions",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin", "editor"],
        navLink: "/manage-topics"
      },
      {
        id: "my_topic_answers",
        title: "My Topic Answers",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin", "editor"],
        navLink: "/my-topic-answers"
      },
      {
        id: "my_favorite_topics",
        title: "My Favorite Topics",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin", "editor"],
        navLink: "/my-favorite-topics"
      },
    ]
  },
  {
    id: "find_tutor",
    title: "Find Tutor",
    type: "item",
    icon: <Icon.User size={20} color="#0e0aeb"/>,
    permissions: ["admin", "editor"],
    navLink: "/find-tutor"
  },
  {
    id: "notifications",
    title: "Notifications",
    type: "item",
    icon: <Icon.Bell size={20} color="#0e0aeb"/>,
    permissions: ["admin", "editor"],
    navLink: "/notifications"
  },
  {
    id: "Logout",
    title: "Logout",
    icon: <Icon.LogOut size={20} color="#ff9f43"/>,
    type: "external-link",
    permissions: ["admin", "editor"],
    navLink: "/authorize?log_out=true"
  }
]

export default navigationConfig
